<template>
  <div>
    <div id="viewer" class="position-relative">
      <div class="rounded position-absolute bg-light p-2" style="top: 20px; right: 20px; z-index: 100">
          <b-button-group v-if="nodes.length > 1" class="mr-2">
            <b-button variant="outline-secondary" @click="switchToPreviousNode" v-b-tooltip.ds200.dh0.hover title="Switch to previous panorama." tabindex="10"><b-icon icon="chevron-double-left"></b-icon></b-button>
            <b-button variant="outline-secondary" @click="switchToNextNode" v-b-tooltip.ds200.dh0.hover title="Switch to next panorama." tabindex="10"><b-icon icon="chevron-double-right"></b-icon></b-button>
          </b-button-group>
          <b-button-group>
            <b-button variant="outline-secondary" @click="checkEditPanoramaMode" v-b-tooltip.ds200.dh0.hover title="Edit panorama properties." tabindex="10"><b-icon icon="pencil-fill"></b-icon></b-button>
            <b-button variant="outline-secondary" @click="setDefaultView" id="default-view" v-b-tooltip.ds200.dh0.hover title="Set the default view for the current panorama." tabindex="10"><b-icon icon="image-alt"></b-icon></b-button>
            <b-button v-if="nodes.length > 1" :variant="currentNode.nodeId == currentProject.startNode.nodeId ? 'outline-info' : 'outline-secondary'" @click="clickStartNode" id="start-node" v-b-tooltip.ds200.dh0.hover :title="currentNode.nodeId == currentProject.startNode.nodeId ? 'This is the start panorama of the project.' : 'Set this panorama as start for the project.'" tabindex="10"><b-icon icon="bookmark-star-fill"></b-icon></b-button>
            <b-button variant="outline-secondary" @click="confirmOpenPreview(currentProject, currentNode.nodeId)" v-b-tooltip.ds200.dh0.hover title="Preview project." tabindex="10"><b-icon icon="binoculars-fill"></b-icon></b-button>
            <b-button v-if="showAccessibilityFeatures" variant="outline-secondary" id="joystick" v-b-tooltip.ds200.dh0.hover title="Move Panorama." @keydown="movePanorama" tabindex="10"><b-icon icon="joystick"></b-icon></b-button>
            <span class="btn-separator"></span>
            <b-button variant="outline-secondary" @click="addHotspotType('unknown')" v-b-tooltip.ds200.dh0.hover id="addHotspotBtn" title="Add a hotspot (or double-click in the panorama)." tabindex="10"><b-icon icon="bullseye" /></b-button>
            <b-popover triggers="" variant="info" target="addHotspotBtn" placement="bottomright" boundary-padding=20 title="Add Hotspot"><div>Add your first hotspot by clicking this button, or double-click inside the panorama.</div></b-popover>
            <b-button variant="outline-secondary" v-b-toggle.hotspotsTableCollapse :disabled="hotspots.length == 0" v-b-tooltip.ds200.dh0.hover title="Toggle hotspots table." tabindex="10"><b-icon icon="table"></b-icon></b-button>
          </b-button-group>
      </div> <!-- toolbar -->
      <b-collapse id="hotspotsTableCollapse" class="card position-absolute" style="min-width: 150px; right: 20px; top: 80px; z-index: 100; overflow-x: auto; overflow-y: hidden;" @hide="toggleHotspotsTableScrollbar(false)" @shown="toggleHotspotsTableScrollbar(true)">
        <div id="hotspotsTable">
          <table class="table table-hover p-1 mb-0">
            <thead class="thead-light">
            <tr>
              <th>Type</th>
              <th>Title</th>
              <th>Delete</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="hotspot in hotspots" :key="hotspot._id" :class="[{'table-active': hotspot == activeHotspot}]" @click="checkSwitchHotspot(hotspot)" @keyup.enter="checkSwitchHotspotKeyboard(hotspot)" tabindex="10">
              <td>
                <b-icon v-if="hotspot.linkType == 'url'" icon="globe"/>
                <b-icon v-else-if="hotspot.linkType == 'node'" icon="geo-fill"/>
                <b-icon v-else-if="hotspot.linkType == 'info'" icon="chat-square-text-fill"/>
                <b-icon v-else-if="hotspot.linkType == 'pdf'" icon="file-earmark-post-fill"/>
                <b-icon v-else-if="hotspot.linkType == 'image'" icon="camera-fill"/>
                <font-awesome-icon v-else-if="hotspot.linkType == 'video/youtube'" :icon="{ prefix: 'fab', iconName: 'youtube' }" />
                <font-awesome-icon v-else-if="hotspot.linkType == 'video/vimeo'" :icon="{ prefix: 'fab', iconName: 'vimeo' }" />
                <b-icon v-else-if="hotspot.linkType == 'video/url'" icon="camera-video-fill"/>
              </td>
              <td>{{ hotspot.title ? hotspot.title : '~empty~'}}</td>
              <td v-on:click="confirmDeleteHotspot(hotspot)"><b-icon icon="trash-fill" style="cursor: pointer;"/></td>
            </tr>
            </tbody>
          </table>
        </div>
      </b-collapse> <!-- hotspotTable -->

      <div v-if="editPanorama && currentNode && Object.keys(currentNode).length > 0" id="panoramaFormDiv" class="card position-absolute" style="min-width: 350px; left: 20px; top: 20px; overflow-y: auto; z-index: 100">
        <div class="card-header">
          <span class="h4">Panorama Properties</span>
          <div class="float-right">
            <b-button variant="outline-warning" class="btn-sm ml-2" @click="rejectPanoramaForm" v-b-tooltip.ds200.dh0.hover title="Close and discard changes." tabindex="20"><b-icon icon="arrow-counterclockwise"></b-icon></b-button>
            <b-button variant="outline-success" class="btn-sm ml-1" @click="acceptPanoramaForm" v-b-tooltip.ds200.dh0.hover title="Close and save changes." tabindex="20"><b-icon icon="check"></b-icon></b-button>
          </div>
        </div>
        <div class="card-body">
          <form id="panoramaForm" class="text-left" @input="formEdited = true">
            <div class="form-group">
              <label for="panoramaTitle">Title</label>
              <input class="form-control" type="text" id="panoramaTitle" placeholder="Enter panorama title..." v-model="currentNode.userdata.title" tabindex="20">
            </div> <!-- form-group Title -->
            <div class="form-group">
              <label class="form-control-label" for="description">Description</label>
              <textarea class="form-control" name="description" id="description" rows="5" placeholder="Enter description here..." v-model="currentNode.userdata.description" tabindex="20"></textarea>
            </div> <!-- form-group Description -->
            <div class="form-group">
              <label class="form-control-label" for="bgsound">Background Sound</label>
              <b-input-group v-if="backgroundSound" class="mb-2">
                <input class="form-control" type="url" id="bgsound" name="bgsound" disabled v-model="backgroundSound.customValues.originalname">
                <b-input-group-append>
                  <b-button variant="outline-secondary" id="bgsound-play" v-b-tooltip.ds200.dh0.hover title="Play/pause background sound." @click="playPauseBgSound" tabindex="20">
                    <audio id="bgsound-audio" crossOrigin="anonymous" :src="'/s3/preview/' + backgroundSound.url"></audio>
                    <b-icon :icon="bgSoundPlaying ? 'pause-fill' : 'play-fill'"></b-icon>
                  </b-button>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button variant="outline-warning" id="bgsound-delete" v-b-tooltip.ds200.dh0.hover title="Delete background sound." @click="confirmDeleteBgSound" tabindex="20">
                    <b-icon icon="trash-fill"></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <vue-dropzone :key="currentNode._id" class="rounded" ref="myVueDropzoneBgSound" id="dropzoneBgSound" :options="dropzoneOptions" :useCustomSlot=true v-on:vdropzone-complete="dropzoneCompleteBgSound" v-on:vdropzone-error="dropzoneError">
                <div class="dropzone-custom-content" @keyup.enter="openBgSoundFileBrowser" tabindex="20">
                  <div style="display: flex; align-items: center;">
                    <div id="dropzone-title" class="dropzone-custom-title h5" style="margin: 0 auto!important;">{{dropzoneTitleBgSound}}</div>
                  </div>
                  <div id="dropzone-filetypes" class="subtitle mt-1">Accepted file types: MP3.</div>
                </div>
              </vue-dropzone>
            </div> <!-- dropzone for bg sound -->
          </form>
        </div>
      </div> <!-- panorama properties -->

      <div v-if="activeHotspot" id="hotspotFormDiv" class="card position-absolute" style="min-width: 350px; left: 20px; top: 20px; z-index: 100">
        <div class="card-header">
          <span class="h4">Hotspot Properties</span>
          <div class="float-right">
            <b-button variant="outline-warning" class="btn-sm ml-2" @click="confirmDeleteHotspot" v-b-tooltip.ds200.dh0.hover title="Delete hotspot."><b-icon icon="trash-fill"></b-icon></b-button>
            <b-button variant="outline-warning" class="btn-sm ml-3" @click="rejectHotspotForm" v-b-tooltip.ds200.dh0.hover title="Close and discard changes."><b-icon icon="arrow-counterclockwise"></b-icon></b-button>
            <b-button variant="outline-success" class="btn-sm ml-1" @click="acceptHotspotForm" v-b-tooltip.ds200.dh0.hover title="Close and save changes."><b-icon icon="check"></b-icon></b-button>
          </div>
        </div>
        <div class="card-body">
          <form id="hotspotForm" class="text-left" @input="formEdited = true">
            <div class="form-group">
              <b-dropdown variant="outline-secondary" ref="hotspotTypeDropdown" no-flip v-b-tooltip.ds200.dh0.hover title="Set hotspot type.">
                <template #button-content>
                  <span v-if="activeHotspot.linkType == 'unknown'">
                    Select Type
                  </span>
                  <span v-if="activeHotspot.linkType == 'url'">
                    <b-icon icon='globe'></b-icon> URL
                  </span>
                  <span v-else-if="activeHotspot.linkType == 'node'">
                    <b-icon icon='geo-fill'></b-icon> Panorama
                  </span>
                  <span v-else-if="activeHotspot.linkType == 'info'">
                    <b-icon icon='chat-square-text-fill'></b-icon> Info
                  </span>
                  <span v-else-if="activeHotspot.linkType == 'pdf'">
                    <b-icon icon='file-earmark-post-fill'></b-icon> PDF
                  </span>
                  <span v-else-if="activeHotspot.linkType == 'image'">
                    <b-icon icon='camera-fill'></b-icon> Image
                  </span>
                  <span v-else-if="activeHotspot.linkType == 'video/youtube'">
                    <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'youtube' }" /> YouTube
                  </span>
                  <span v-else-if="activeHotspot.linkType == 'video/vimeo'">
                    <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'vimeo' }" /> Vimeo
                  </span>
                  <span v-else-if="activeHotspot.linkType == 'video/url'">
                    <b-icon icon='camera-video-fill'></b-icon> Video (URL)
                  </span>
                </template>
                <b-dropdown-item-button @click="changeHsType('url')">
                  <b-icon icon="globe"></b-icon> URL
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="changeHsType('node')">
                  <b-icon icon="geo-fill"></b-icon> Panorama
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="changeHsType('info')">
                  <b-icon icon="chat-square-text-fill"></b-icon> Info
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="changeHsType('pdf')">
                  <b-icon icon="file-earmark-post-fill"></b-icon> PDF
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="changeHsType('image')">
                  <b-icon icon="camera-fill"></b-icon> Image
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="changeHsType('video/youtube')">
                  <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'youtube' }" /> YouTube
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="changeHsType('video/vimeo')">
                  <font-awesome-icon  :icon="{ prefix: 'fab', iconName: 'vimeo' }" /> Vimeo
                </b-dropdown-item-button>
                <b-dropdown-item-button @click="changeHsType('video/url')">
                  <b-icon icon="camera-video-fill"></b-icon> Video (URL)
                </b-dropdown-item-button>
              </b-dropdown>
             </div> <!-- form-group Type -->
            <div v-show="activeHotspot.linkType != 'unknown'" class="form-group">
              <input class="form-control" type="text" id="hotspotTitle" placeholder="Enter hotspot title..." v-model="activeHotspot.title">
            </div> <!-- form-group Title -->
            <div v-if="activeHotspot.linkType =='info' || activeHotspot.linkType =='url' || activeHotspot.linkType =='pdf' || activeHotspot.linkType =='image' || activeHotspot.linkType =='video/youtube' || activeHotspot.linkType =='video/vimeo' || activeHotspot.linkType =='video/url'" class="form-group">
              <label class="form-control-label" for="description">Caption</label>
              <textarea class="form-control" name="description" id="description" rows="5" placeholder="Enter caption here..." v-model="activeHotspot.description"></textarea>
            </div> <!-- form-group Description -->
            <div v-if="activeHotspot.linkType == 'url'" class="form-group">
              <b-form-radio v-model="activeHotspot.customValues.openintab" name="openintab" value=false>Display in Tour</b-form-radio>
              <b-form-radio v-model="activeHotspot.customValues.openintab" name="openintab" value=true>Open in New Tab</b-form-radio>
            </div>
            <div v-if="activeHotspot.customValues && activeHotspot.customValues.alttext != undefined && (activeHotspot.linkType == 'image' || activeHotspot.linkType =='video/youtube' || activeHotspot.linkType =='video/vimeo' || activeHotspot.linkType =='video/url' || activeHotspot.linkType == 'pdf')" class="form-group">
              <label class="form-control-label" for="alttext">Alt Text</label>
              <textarea class="form-control" name="alttext" id="alttext" rows="3" placeholder="Enter alt text here..." v-model="activeHotspot.customValues.alttext"></textarea>
            </div> <!-- form-group Alt Text -->
            <div v-if="activeHotspot.linkType != 'info'" class="form-group">
              <div v-if="activeHotspot.linkType == 'node'"> <!-- node -->
                <label class="form-control-label" for="node">Panorama</label>
                <b-input-group>
                  <select class="form-control" id="node" name="node" v-model="activeHotspot.targetNode">
                    <option disabled value="">Please select a panorama...</option>
                    <option v-for="node in nodes" :key="node._id" :value="node._id">{{ node.userdata.title != '' ? node.userdata.title : node.nodeId }}</option>
                  </select>
                  <b-input-group-append>
                    <b-button variant="outline-secondary" id="switch-node" :disabled="!activeHotspot.targetNode" v-b-tooltip.ds200.dh0.hover title="Switch to this panorama." @click="switchToNodeById(activeHotspot.targetNode)">
                      <b-icon icon="geo-fill"></b-icon>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div v-else-if="activeHotspot.linkType == 'url'"> <!-- url -->
                <label class="form-control-label" for="url">URL</label>
                <b-input-group>
                  <input class="form-control" type="url" id="url" name="url" placeholder="URL" v-model="activeHotspot.url">
                  <b-input-group-append>
                    <b-button variant="outline-secondary" id="url-open" v-b-tooltip.ds200.dh0.hover title="Open URL." :disabled="activeHotspot.url == ''" @click="openUrl">
                      <b-icon icon="globe"></b-icon>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div v-else-if="activeHotspot.linkType =='image' || activeHotspot.linkType == 'pdf'"> <!-- images & pdf -->
                <div v-if="activeHotspot.customValues && activeHotspot.customValues.originalname && activeHotspot.customValues.originalname != ''">
                  <label class="form-control-label" for="file">File</label>
                  <b-input-group>
                    <input class="form-control" type="url" id="media-file" name="file" :placeholder="activeHotspot.linkType == 'image' ? 'Image File' : 'PDF File'" readonly v-model="activeHotspot.customValues.originalname" tabindex="-1">
                    <b-input-group-append>
                      <b-button variant="outline-secondary" id="media-open" v-b-tooltip.ds200.dh0.hover :title="activeHotspot.linkType == 'image' ? 'Open Image.' : 'Open PDF.'" @click="openMediaFile">
                        <b-icon :icon="activeHotspot.linkType == 'image' ? 'camera-fill' : 'file-earmark-post-fill'"></b-icon>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <vue-dropzone v-if="activeHotspot.linkType=='image' || activeHotspot.linkType=='pdf' || activeHotspot.linkType=='video/file'" :key="activeHotspot._id" class="mt-2 rounded" ref="myVueDropzoneHs" id="dropzoneHs" :options="dropzoneOptions" :useCustomSlot=true v-on:vdropzone-complete="dropzoneCompleteHs" v-on:vdropzone-error="dropzoneError">
                  <div class="dropzone-custom-content" @keyup.enter="openHsMediaFileBrowser" tabindex="0">
                    <div style="display: flex; align-items: center;">
                      <img v-if="dropzonePreviewPathHs != ''" id="dropzone-preview" class="mr-2" style="max-width: 150px; max-height: 150px; width: auto; height: auto;" :src="dropzonePreviewPathHs"/>
                      <div id="dropzone-title" class="dropzone-custom-title h5" style="margin: 0 auto!important;">{{dropzoneTitleHs}}</div>
                    </div>
                    <div id="dropzone-filetypes" class="subtitle mt-1">{{dropzoneFiletypes}}</div>
                  </div>
                </vue-dropzone>
              </div>
              <div v-else-if="activeHotspot.linkType =='video/youtube' || activeHotspot.linkType == 'video/vimeo' || activeHotspot.linkType =='video/url'"> <!-- youtube & vimeo & url videos -->
                <label v-if="activeHotspot.linkType == 'video/youtube'" class="form-control-label">YouTube Video</label>
                <label v-else-if="activeHotspot.linkType == 'video/vimeo'" class="form-control-label">Vimeo Video</label>
                <label v-else class="form-control-label">Video URL</label>
                <b-input-group>
                  <input v-if="activeHotspot.linkType == 'video/youtube'" class="form-control" type="url" id="youtube-url" name="youtube-url" value="" placeholder="Paste YouTube URL here..." @change="youtubeUrlChanged=true" @blur="parseYoutubeUrl">
                  <input v-else-if="activeHotspot.linkType == 'video/vimeo'" class="form-control" type="url" id="vimeo-url" name="vimeo-url" placeholder="Paste Vimeo URL here..." @change="vimeoUrlChanged=true" @blur="parseVimeoUrl">
                  <input v-else class="form-control" type="url" id="video-url" name="video-url" placeholder="Enter Video URL here..." v-model="activeHotspot.url">
                  <b-input-group-append>
                    <b-button v-if="activeHotspot.linkType == 'video/youtube'" id="youtube-open" variant="outline-secondary" disabled v-b-tooltip.ds200.dh0.hover title="Open video." @click="openYoutubeVideo">
                      <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'youtube' }" />
                    </b-button>
                    <b-button v-else-if="activeHotspot.linkType == 'video/vimeo'" id="vimeo-open" variant="outline-secondary" disabled v-b-tooltip.ds200.dh0.hover title="Open video." @click="openVimeoVideo">
                      <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'vimeo' }" />
                    </b-button>
                    <b-button v-else id="video-open" variant="outline-secondary" :disabled="activeHotspot.url == ''" v-b-tooltip.ds200.dh0.hover title="Open video." @click="openVideoUrl">
                      <b-icon icon="camera-video-fill"></b-icon>
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div v-if="activeHotspot.linkType == 'video/url'" class="mt-3"><b-icon variant="primary" class="mr-1" icon="info-circle"></b-icon>Advanced feature. To use see <b-link href="https://docs.google.com/document/d/1dyRZuLTxO4S0WziMj9B5SLYBKpufiPsco1wnjdKRm9g/edit?usp=sharing" target="_blank">FAQ</b-link>.</div>
            </div> <!-- form-group Target URL -->
          </form>
        </div>
      </div> <!-- hotspotForm -->

      <div id="container" class="display-flex" style="height: 75vh; width:100%; overflow:hidden;"></div> <!-- pano container -->
    </div>
  </div>
</template>


<script>
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { HelperFunctionsMixin } from "../mixins/helperFunctionsMixin"

export default {
  name: 'PanoEditor',
  mixins: [HelperFunctionsMixin],
  components: {
    vueDropzone: vue2Dropzone,
    FontAwesomeIcon,
  },
  data() {
    return {
      pano: null,
      formEdited: false,
      editPanorama: false,
      hotspotsTableVisible: false,
      bgSoundPlaying: false,
      tmpPanoramaTitle: '',
      tmpPanoramaDescription: '',
      youtubeUrlChanged: false,
      vimeoUrlChanged: false,
      dropzoneOptions: {
        url: '/api/project/_not_set_/uploadasset',
        thumbnailWidth: 150,
        maxFilesize: 10,
        maxFiles: 1,
        uploadMultiple: false,
        headers: { 'X-Dropzone': 'hotspot' },
        paramName: "files",
        createImageThumbnails: true,
        dictDefaultMessage: 'Drop file here to upload.',
        init: function() {
          // only allow a single file in the drop zone
          this.on('addedfile', function() {
            if (this.files.length > 1) {
              this.removeFile(this.files[0]);
            }
          });
        }
      },
      dropzonePreviewPathHs: '',
      dropzoneTitleHs: 'Drop file here to upload.',
      dropzoneFiletypes: '',
      dropzoneTitleBgSound: 'Drop audio file to upload',
      firstOpen: true,
      tmpFocusElement: null
    }
  },
  computed: {
    ...mapState('navigation', ['currentNode','currentProject']),
    ...mapState('nodes', ['nodes']),
    ...mapState('hotspots', ['hotspots', 'hotspotsBackup', 'activeHotspot']),
    ...mapState('sounds', ['sounds', 'backgroundSound']),
    ...mapState('user', ['token','jwt','showAccessibilityFeatures']),
    activeLinkType: function() {
      return (this.activeHotspot ? this.activeHotspot.linkType : 'none');
    }
  },
  created: function()
  {
  },
  mounted: function()
  {
    if (this.pano==null) {
      // eslint-disable-next-line
      this.pano = new pano2vrPlayer("container");
      this.pano.addListener('doubleclick', this.playerDoubleClicked);
      this.pano.addListener('panoclick', this.playerPanoClicked);
      this.pano.addListener('configloaded', this.updatePlayerHotspots);
      window.addEventListener('resize', this.resizeUIElements);
    } else {
      this.pano.setContainer("container");
    }
    this.setPlayerNode();
    this.resizeUIElements();
    // setup drop zone
    this.dropzoneOptions.url = '/api/project/' + this.currentProject._id + '/uploadasset';
    this.dropzoneOptions.headers.Authorization = "Bearer " + this.token;

    document.addEventListener('keyup', this.focusJoystick);
  },
    activated: function()
  {
    this.editPanorama = false;
    this.resizeUIElements();
  },
  updated: function()
  {
    this.resizeUIElements();
    this.pano.updateViewerSizeNow();

    let audioEl = document.getElementById('bgsound-audio');
    if (audioEl && !audioEl.onplay) {
      let me = this;
      audioEl.onplay = function() {me.bgSoundPlaying = true;};
      audioEl.onpause = function() {me.bgSoundPlaying = false;};
      audioEl.onended = function() {me.bgSoundPlaying = false;};
    }
  },
  beforeRouteLeave: async function(to, from, next)
  {
    if (await this.checkHotspotData(this.activeHotspot)) {
      this.saveForm();
      this.$root.$emit('bv::hide::popover');
      next();
    }
  },
  destroyed: function()
  {
    document.removeEventListener('keyup', this.focusJoystick);
  },
  methods: {
    ...mapActions('navigation', ['setCurrentNode']),
    ...mapActions('nodes', ['saveNode', 'setStartNode']),
    ...mapActions('hotspots', ['addHotspot', 'saveHotspot', 'deleteHotspot', 'setActiveHotspot', 'setActiveHotspotById', 'refreshHotspotsFromBackup']),
    ...mapActions('sounds', ['addSound', 'saveSound', 'deleteSound', 'backupSounds', 'refreshSoundsFromBackup']),
    ...mapActions({ alertSuccess: 'alert/success', alertError: 'alert/error' }),
    async saveForm() {
      if (this.formEdited) {
        if (this.editPanorama) {
          await this.acceptPanoramaForm();
        } else {
          await this.saveHotspotForm(this.activeHotspot);
        }
      }
      this.formEdited = this.editPanorama = false;
    },
    async playerDoubleClicked(params) {
      if (await this.checkHotspotData(this.activeHotspot)) {
        await this.saveForm();
        if (this.currentNode && this.currentNode._id) {
          let response = await this.addHotspot(params);
          this.formEdited = true;
          if (response && response.status == 200) {
            this.addPlayerHotspot(this.activeHotspot);
          }
        }
      }
    },
    setPlayerNode() {
      if ((this.currentNode) && (this.currentNode._id)) {
        this.pano.readConfigUrlAsync("/api/node/" + this.currentNode._id + "/pano.xml?_jwt=" + this.jwt);
      }
    },
    async switchToNode(node) {
      if (await this.checkHotspotData(this.activeHotspot)) {
        this.saveForm();
        this.setActiveHotspot(null);
        this.setCurrentNode(node);
      }
    },
    switchToPreviousNode() {
      let prevNodeIndex = (this.nodes.indexOf(this.currentNode) + this.nodes.length - 1) % this.nodes.length;
      this.switchToNode(this.nodes[prevNodeIndex]);
    },
    switchToNextNode() {
      let prevNodeIndex = (this.nodes.indexOf(this.currentNode) + 1) % this.nodes.length;
      this.switchToNode(this.nodes[prevNodeIndex]);
    },
    switchToNodeById(id) {
      let targetNode = this.nodes.find(element => element._id == id);
      if (targetNode) {
        this.switchToNode(targetNode);
      }
    },
    playerPanoClicked() {
    },
    focusJoystick(e) {
      if (e.key === 'Alt') {
        let joystickButton = document.querySelector('#joystick');
        if (document.activeElement === joystickButton) {
          if (this.tmpFocusElement) {
            this.tmpFocusElement.focus();
          }
        } else {
          this.tmpFocusElement = document.activeElement;
          joystickButton.focus();
        }
      }
    },
    movePanorama(e) {
      switch(e.key) {
        case 'ArrowLeft':
          this.pano.changePanLog(0.5, true);
          break;
        case 'ArrowRight':
          this.pano.changePanLog(-0.5, true);
          break;
        case 'ArrowUp':
          this.pano.changeTiltLog(0.5, true);
          break;
        case 'ArrowDown':
          this.pano.changeTiltLog(-0.5, true);
          break;
        case 'Shift':
          this.pano.changeFovLog(-0.5, true);
          break;
        case 'Control':
          this.pano.changeFovLog(0.5, true);
          break;
      }
    },
    dropzoneCompleteHs(response) {
      if (response.status=="success") {
        let data = JSON.parse(response.xhr.response);
        if (data.length>=1) {
          let backupHs = this.hotspotsBackup.find(element => element._id == this.activeHotspot._id);
          if (backupHs && backupHs.url != this.activeHotspot.url) {
            this.deleteAsset(this.activeHotspot.url);
          }
          this.activeHotspot.url = data[0].url;
          if (!this.activeHotspot.customValues) {
            this.activeHotspot.customValues={};
          }
          this.activeHotspot.customValues.originalname=data[0].originalname;
          this.formEdited = true;
        }
      }
    },
    openHsMediaFileBrowser() {
      this.$refs.myVueDropzoneHs.$el.dropzone.hiddenFileInput.click();
    },
    dropzoneCompleteBgSound(response) {
      if (response.status=="success") {
        let data = JSON.parse(response.xhr.response);
        if (data.length>=1) {
          if (this.backgroundSound) {
            this.backgroundSound.url = data[0].url;
            this.backgroundSound.customValues.originalname = data[0].originalname;
            this.saveSound(this.backgroundSound);
          } else {
            let params = {};
            params['soundId'] = '_background';
            params['type'] = 'static';
            params['url'] = data[0].url;
            let customValues = {};
            customValues['originalname'] = data[0].originalname;
            params['customValues'] = customValues;
            this.addSound(params);
          }
          this.formEdited = true;
        }
      }
    },
    openBgSoundFileBrowser() {
      this.$refs.myVueDropzoneBgSound.$el.dropzone.hiddenFileInput.click();
    },
    dropzoneError(file, message) {
      this.alertError(message.error);
    },
    toggleHotspotsTableScrollbar(show) {
      document.getElementById('hotspotsTableCollapse').style.overflowY = show ? 'auto' : 'hidden';
      this.hotspotsTableVisible = show;
    },
    async setDefaultView() {
      let currentPan = this.pano.getPan();
      let currentTilt = this.pano.getTilt();
      let currentFov = this.pano.getFov();
      let currentProjection = this.pano.getProjection();
      this.currentNode.view.default.pan = currentPan;
      this.currentNode.view.default.tilt = currentTilt;
      this.currentNode.view.default.fov = currentFov;
      this.currentNode.view.default.projection = currentProjection;
      let payload = {};
      payload.node = this.currentNode;
      payload.data = {};
      payload.data.view = {default: {pan: currentPan, tilt: currentTilt, fov: currentFov, projection: currentProjection}};
      let response = await this.saveNode(payload);
      if (response && response.status == 200) {
        this.alertSuccess('Default view successfully set!');
      } else {
        this.alertError('Problem setting default view!');
      }
    },
    async clickStartNode() {
      if (this.currentNode.nodeId != this.currentProject.startNode.nodeId) {
        let response = await this.setStartNode(this.currentNode);
        if (response && response.status == 200) {
          this.alertSuccess('Start Panorama successfully set!');
          this.currentProject.startNode.id = this.currentNode._id;
          this.currentProject.startNode.nodeId = this.currentNode.nodeId;
          this.currentProject.startNode.folder = this.currentNode.image.folder;
        }
      }
    },
    changeHsType(type) {
      this.activeHotspot.linkType = type;
      this.activeHotspot.url = '';
      if (this.activeHotspot.linkType == 'video/youtube') {
        this.setYoutubeUrlInUI();
      } else if (this.activeHotspot.linkType == 'video/vimeo') {
        this.setVimeoUrlInUI();
      } else if (this.activeHotspot.linkType == 'video/url') {
      }
      this.formEdited = true;
    },
    async addHotspotType(type) {
      if (await this.checkHotspotData(this.activeHotspot)) {
        await this.saveForm();
        let viewerSize = this.pano.getViewerSize();
        let hsPos = this.pano.getPositionAngles(viewerSize.width / 2, viewerSize.height /2, true);
        let collision;
        let tries = 0;       
        do {
          collision = false;
          for (const element of this.hotspots) {
            if ((Math.abs(element.pan + hsPos.pan) < 3) && (Math.abs(element.tilt - hsPos.tilt) < 3)) {
              hsPos.pan += 3;
              collision = true;
              tries++;
            }
          }
        } while (collision && tries < 120);
        let params = {};
        params['linkType'] = type;
        params['pan'] = -hsPos.pan;
        params['tilt'] = hsPos.tilt;
        let response = await this.addHotspot(params);
        this.formEdited = true;
        if (response && response.status == 200) {
          this.addPlayerHotspot(this.activeHotspot);
          Vue.nextTick(() => {
            let hsTypeCombobox = this.$refs.hotspotTypeDropdown.$el;
            hsTypeCombobox.children[0].focus();
          });
        }
      }
    },
    hotspotIconForType(type, active) {
      var icon = "";
      switch (type) {
        case "unknown":
          icon = "./pano/hotspot_unknown_red.svg";
          break;
        case "url":
          icon = active ? "./pano/hotspot_url_red.svg" : "./pano/hotspot_url.svg";
          break;
        case "node":
          icon = active ? "./pano/hotspot_node_red.svg" : "./pano/hotspot_node.svg";
          break;
        case "info":
          icon = active ? "./pano/hotspot_info_red.svg" : "./pano/hotspot_info.svg";
          break;
        case "image":
          icon = active ? "./pano/hotspot_image_red.svg" : "./pano/hotspot_image.svg";
          break;
        case "pdf":
          icon = active ? "./pano/hotspot_pdf_red.svg" : "./pano/hotspot_pdf.svg";
          break;
        case "video/youtube":
          icon = active ? "./pano/hotspot_youtube_red.svg" : "./pano/hotspot_youtube.svg";
          break;
          case "video/vimeo":
          icon = active ? "./pano/hotspot_vimeo_red.svg" : "./pano/hotspot_vimeo.svg";
          break;
          case "video/url":
          icon = active ? "./pano/hotspot_video_red.svg" : "./pano/hotspot_video.svg";
          break;
        default:
          break;
      }
      return icon;
    },
    addPlayerHotspot(hotspot) {
      let me = this;
      function makeHotspotInteractive(hotspot) {
        hotspot.pos1 = 0;
        hotspot.pos2 = 0;
        hotspot.pos3 = 0;
        hotspot.pos4 = 0;

        function commitHotspotMove() {
            if (hotspot.moved) {
            hotspot.moved = false;
            let elRect = hotspot.getBoundingClientRect();
            let hsDivRect = hotspot.parentElement.parentElement.getBoundingClientRect();
            let newPos = me.pano.getPositionAngles((elRect.left - hsDivRect.left) + 15, (elRect.top - hsDivRect.top) + 15, true);
            hotspot.style.top = "-15px";
            hotspot.style.left = "-15px";
            me.activeHotspot.pan = Math.round(-newPos['pan'] * 100) / 100;
            me.activeHotspot.tilt = Math.round(newPos['tilt'] * 100) / 100;
            me.moveHotspot(me.activeHotspot);
          }
        }

        async function clickOrTouch(e) {
          function clickOrTouchEnd() {
            document.onmouseup = null;
            document.ontouchend = null;
            document.onmousemove = null;
            document.onpointermove = null;
            commitHotspotMove();
          }

          function moveOrDrag(e) {
            let tooltip = hotspot.parentElement.querySelector('#tooltip');
            if (tooltip) tooltip.style.visibility = 'hidden';
            e = e || window.event;
            e.preventDefault();
            hotspot.moved = true;
            let clientX = e.touches ? e.touches[0].clientX : e.clientX;
            let clientY = e.touches ? e.touches[0].clientY : e.clientY;
            hotspot.pos1 = hotspot.pos3 - clientX;
            hotspot.pos2 = hotspot.pos4 - clientY;
            hotspot.pos3 = clientX;
            hotspot.pos4 = clientY;
            hotspot.style.top = (hotspot.offsetTop - hotspot.pos2) + "px";
            hotspot.style.left = (hotspot.offsetLeft - hotspot.pos1) + "px";
          }

          // on touch devices show tooltip above hs
          if (e.touches) {
            let tooltip = hotspot.parentElement.querySelector('#tooltip');
            if (tooltip) tooltip.style.top = '-50px';
          }

          let tmpHsId = me.activeHotspot ? me.activeHotspot._id : null;
          if (hotspot._hsId != tmpHsId) {
            if (await me.checkHotspotData(me.activeHotspot)) {
              if (me.activeHotspot && me.activeHotspot.linkType != 'unknown') {
                me.saveForm();
              }
              me.setActiveHotspotById(hotspot._hsId);
            }
            return;
          } else {
            e = e || window.event;
            e.preventDefault();
            let clientX = e.touches ? e.touches[0].clientX : e.clientX;
            let clientY = e.touches ? e.touches[0].clientY : e.clientY;
            hotspot.pos3 = clientX;
            hotspot.pos4 = clientY;
            hotspot.moved = false;
          }

          if (e.touches) {
            document.onpointermove = moveOrDrag;
            document.onpointerup = clickOrTouchEnd;
          } else {
            document.onmousemove = moveOrDrag;
            document.onmouseup = clickOrTouchEnd;
          }
        }

        function keyDown(e) {
          switch(e.key) {
            case 'ArrowLeft':
              hotspot.moved = true;
              hotspot.style.left = (hotspot.offsetLeft - 5) + "px";
              break;
            case 'ArrowRight':
              hotspot.moved = true;
              hotspot.style.left = (hotspot.offsetLeft + 5) + "px";
              break;
            case 'ArrowUp':
              hotspot.moved = true;
              hotspot.style.top = (hotspot.offsetTop - 5) + "px";
              break;
            case 'ArrowDown':
              hotspot.moved = true;
              hotspot.style.top = (hotspot.offsetTop + 5) + "px";
              break;
            case 'Tab':
              document.querySelector('#hotspotTitle').focus();
              e.preventDefault();
              break;
          }
        }

        hotspot.onmousedown = clickOrTouch;
        hotspot.ontouchstart = clickOrTouch;
        hotspot.onkeydown = keyDown;
        hotspot.onkeyup = commitHotspotMove;
      }
     
      let hsImg = document.createElement('img');
      hsImg.setAttribute('style', 'position: absolute; width: 30px; height: 30px; left: -15px; top: -15px;');
      hsImg.setAttribute('src', this.hotspotIconForType(hotspot.linkType, hotspot == this.activeHotspot));
      hsImg._hsId = hotspot._id;
      hsImg['ondragstart'] = function() {
        return false;
      };
      hsImg['onblur'] = function() {
        hsImg.setAttribute('tabindex', '-1');
      };
      hotspot.__div = hsImg;
      this.hotspotsBackup[this.hotspots.indexOf(hotspot)].__div = hsImg;
      makeHotspotInteractive(hsImg);
      this.pano.addHotspot(hotspot._id, hotspot.pan, hotspot.tilt, hsImg);
      if (hotspot.title && hotspot.title != '') {
        let hsTooltip = document.createElement('div');
        hsTooltip.setAttribute('id', 'tooltip');
        hsTooltip.setAttribute('style', 'position: absolute; top: 20px; visibility: hidden; overflow: hidden; border: none; border-radius: 3px; text-align: center; background-color: black; color: white; height: auto; width: auto; white-space: nowrap; padding: 0px 6px 0px 6px');
        hsTooltip.innerHTML = hotspot.title;
        hsImg.parentElement.appendChild(hsTooltip);
        hsImg.onmouseover = () => {
          if (hotspot.__div.moved) return;
          let w = hsTooltip.offsetWidth;
          hsTooltip.style.left = (-w / 2) + 'px';
          hsTooltip.style.visibility = 'inherit';
        };
        hsImg.onmouseout = () => {
          hsTooltip.style.visibility = 'hidden';
        };
      }
    },
    moveHotspot(hotspot) {
      if (!hotspot) return;
      this.saveHotspot(hotspot);
      this.pano.updateHotspot(hotspot._id, hotspot.pan, hotspot.tilt);
    },
    updatePlayerHotspots() {
      this.pano.removeHotspots();
      this.hotspots.forEach(element => {
        this.addPlayerHotspot(element);
      });
    },
    async checkHotspotData(hotspot) {
      if (!hotspot || (hotspot.linkType == 'unknown')) return true;
     
      let error = false;
      let errorMessage = '';

      // check for 'empty' hotspot
      if (hotspot.title == '') {
        error = true;
        errorMessage = '• Missing title';
      }
      if (hotspot.linkType == 'node' && hotspot.targetNode == '') {
        error = true;
        errorMessage = (errorMessage != '' ? errorMessage + '\n' : '') + '• Missing target panorama';
      }
      if (hotspot.linkType == 'info' && hotspot.description == '') {
        error = true;
        errorMessage = (errorMessage != '' ? errorMessage + '\n' : '') + '• Missing caption';
      }
      if (hotspot.linkType == 'url' && hotspot.url == '') {
        error = true;
        errorMessage = (errorMessage != '' ? errorMessage + '\n' : '') + '• Missing URL';
      }
      if ((hotspot.linkType == 'image' || hotspot.linkType == 'pdf' || hotspot.linkType == 'video/youtube' || hotspot.linkType == 'video/vimeo' || hotspot.linkType == 'video/url') && hotspot.url == '') {
        error = true;
        errorMessage = (errorMessage != '' ? errorMessage + '\n' : '') + '• Missing media content';
      }

      if (hotspot.linkType == 'url') { // check url
        let urlInfo = await this.checkUrl(hotspot.url);
        let validUrl = urlInfo.hasOwnProperty('valid') && urlInfo.valid === true;
        if (!validUrl) {
          error = true;
          errorMessage = (errorMessage != '' ? errorMessage + '\n' : '') + '• Invalid URL';
        }
        let allowIframe = urlInfo.hasOwnProperty('iframe') && urlInfo.iframe === true;
        if (!allowIframe && (hotspot.customValues.openintab==='false' || hotspot.customValues.openintab===false)) {
          error = true;
          errorMessage = (errorMessage != '' ? errorMessage + '\n' : '') + '• URL cannot be displayed in the tour (select \'Open in New Tab\' instead)';
        }
      }

      if (error) {
        this.$bvModal.msgBoxOk(errorMessage, {
            title: 'Hotspot data missing or invalid:',
            okVariant: 'warning',
            headerClass: 'p-2 bg-warning',
            bodyClass: 'warning-text-wrap',
            footerClass: 'p-2 border-top-0',
            centered: true
          });
      }
      return !error;
    },
    async checkEditPanoramaMode() {
      if (await this.checkHotspotData(this.activeHotspot)) {
        this.saveForm();
        this.setActiveHotspot(null);
        this.editPanorama = true;
        Vue.nextTick(() => {
          document.querySelector('#panoramaTitle').focus();
        });
      }
    },
    async checkSwitchHotspot(hotspot) {
      if (await this.checkHotspotData(this.activeHotspot)) {
        this.saveForm(); 
        this.setActiveHotspot(hotspot); 
        this.pano.moveTo(this.activeHotspot.pan, this.activeHotspot.tilt, this.pano.getFov(), 4);
        return true;
      }
      return false;
    },
    async checkSwitchHotspotKeyboard(hotspot) {
      if (await this.checkSwitchHotspot(hotspot)) {
        hotspot.__div.setAttribute('tabindex', '0');
        hotspot.__div.focus();
      }
    },
    async saveHotspotForm(hotspot) {
      if (!hotspot) return;
      if (hotspot.linkType == 'unknown') {
            let response = await this.deleteHotspot(hotspot);
            if (response && response.status == 200) {
              this.pano.removeHotspot(hotspot._id);
              this.formEdited = false;
            }
            return;
      }
      if ((hotspot.linkType=='image') || (hotspot.linkType=='pdf')) { // if changed, delete old asset
        let backupHs = this.hotspotsBackup.find(element => element._id == hotspot._id);
        if (backupHs.url != '' && backupHs.url != hotspot.url) {
          this.deleteAsset(backupHs.url);
        }
      }
      let response = await this.saveHotspot(hotspot);
      if (response && response.status == 200) {
        let savedHotspot = this.hotspots.find(element => element._id == hotspot._id);
        this.pano.removeHotspot(hotspot._id);
        this.addPlayerHotspot(savedHotspot);
        this.formEdited = false;
      }
    },
    async rejectHotspotForm() {
      let backupHs = this.hotspotsBackup.find(element => element._id == this.activeHotspot._id);
      if (backupHs.linkType == 'unknown') {
            let response = await this.deleteHotspot(this.activeHotspot);
            if (response && response.status == 200) {
              this.pano.removeHotspot(this.activeHotspot._id);
            }
      } else {
        this.refreshHotspotsFromBackup();
      }
      this.setActiveHotspot(null);
      this.formEdited = false;
    },
    async acceptHotspotForm() {
      if (await this.checkHotspotData(this.activeHotspot)) {
        this.saveForm();
        this.setActiveHotspot(null);
      }
    },
    async deleteAsset(url) {
      try {
        let urlString = new String(url);
        let uri = '/api/project/' + this.currentProject._id + '/asset/' + urlString.substring(urlString.lastIndexOf('/') + 1);
        await axios.delete(uri);
      } catch (e) {
        console.log(e);
      }
    },
    async confirmDeleteHotspot() {
      this.$bvModal.msgBoxConfirm('Are you sure?')
        .then(async value => {
          if (value) {
            let response = await this.deleteHotspot(this.activeHotspot);
            if (response && response.status == 200) {
              if ((this.activeHotspot.url) && ((this.activeHotspot.linkType=='image') || (this.activeHotspot.linkType=='pdf'))) {
                this.deleteAsset(this.activeHotspot.url);
              }
              this.pano.removeHotspot(this.activeHotspot._id);
              this.setActiveHotspot(null);
              this.formEdited = false;
            }
          }
       });
    },
    confirmDeleteBgSound() {
      this.$bvModal.msgBoxConfirm('Are you sure?')
        .then(async value => {
          if (value) {
            this.deleteAsset(this.backgroundSound.url);
            let response = await this.deleteSound(this.backgroundSound);
            if (response && response.status == 200) {
              this.formEdited = true;
              this.$refs.myVueDropzoneBgSound.removeAllFiles();
            }
          }
       });
    },
    playPauseBgSound() {
      let audioEl = document.getElementById('bgsound-audio');
      if (!this.bgSoundPlaying) {
        audioEl.play();
      } else {
        audioEl.pause();
      }
    },
    resizeUIElements() {
      let headerEl = document.getElementById('header');
      if (!headerEl) return;
      let headerHeight = headerEl.offsetHeight;
      let playerHeight = window.innerHeight - headerHeight;
      let playerDiv = document.getElementById('container');
      if (playerDiv) playerDiv.style.height = playerHeight + 'px';
      let formDiv = document.getElementById('hotspotFormDiv');
      if (formDiv) formDiv.style.maxHeight = (playerHeight - 40) + 'px';
      formDiv = document.getElementById('hotspotsTable');
      if (formDiv) formDiv.style.maxHeight = (playerHeight - 120) + 'px';
    },
    openUrl() {
      let url = document.querySelector('#url').value;
      var tab = window.open(url, '_blank');
      tab.focus();
    },
    openMediaFile() {
      let url = '/s3/preview/' + this.activeHotspot.url;
      var tab = window.open(url, '_blank');
      tab.focus();
    },
    parseYoutubeUrl() {
      if (this.youtubeUrlChanged) {
        let successfullyParsed = false;
        let url = document.querySelector('#youtube-url').value;
        if (url != '') {
          try {
            let id;
            let youtubeUrl = new URL(url);
            if (youtubeUrl.host == 'www.youtube.com' || youtubeUrl.host == 'youtube.com') {
              id = youtubeUrl.searchParams.get('v');
              if (youtubeUrl.pathname.startsWith("/shorts/")) {
               id=youtubeUrl.pathname.split("/")[2]; // first is empty... before /
              }
            }
            if (youtubeUrl.host == 'youtu.be') {
              id = youtubeUrl.pathname.substr(1); // cut of the /
            }
            // https://youtu.be/iik25wqIuFo
            RegExp('^[0-9]*$');
            if (id && id != '') {
              this.activeHotspot.url = id;
              successfullyParsed = true;
              this.formEdited = true;
            }
          } catch {
            successfullyParsed = false;
          }
        }
        this.enableOpenYoutube(successfullyParsed);
        if (!successfullyParsed) {
          if (url != '') {
            this.alertError('Problem parsing YouTube URL!');
          }
          this.activeHotspot.url = '';
          this.formEdited = true;
        }
      }
      this.youtubeUrlChanged = false;
    },
    setYoutubeUrlInUI() {
      Vue.nextTick(() => {
        let urlInput = document.querySelector('#youtube-url');
        if (this.activeHotspot && this.activeHotspot.url != '') {
          let youtubeUrl = 'https://youtube.com/watch?v=' + this.activeHotspot.url;
          urlInput.value = youtubeUrl;
          this.enableOpenYoutube(true);
        } else if (urlInput) {
          urlInput.value = "";
          this.enableOpenYoutube(false);
        }
      });
    },
    enableOpenYoutube(enable) {
      let openButton = document.querySelector('#youtube-open');
      if (enable) {
        openButton.removeAttribute('disabled');
        openButton.classList.remove('disabled');
      } else {
        let att = document.createAttribute('disabled');
        openButton.setAttributeNode(att);
        openButton.classList.add('disabled');
      }
    },
    openYoutubeVideo() {
      let url = document.querySelector('#youtube-url').value;
      var tab = window.open(url, '_blank');
      tab.focus();
    },
    parseVimeoUrl() {
      if (this.vimeoUrlChanged) {
        let successfullyParsed = false;
        let url = document.querySelector('#vimeo-url').value;
        if (url != '') {
          try {
            let vimeoUrl = new URL(url);
            if (vimeoUrl.host == 'www.vimeo.com' || vimeoUrl.host == 'vimeo.com') {
              let id = vimeoUrl.pathname.slice(vimeoUrl.pathname.lastIndexOf('/') + 1);
              let regExpNumerical = RegExp('^[0-9]*$');
              if (id && id != '' && regExpNumerical.test(id)) {
                this.activeHotspot.url = id;
                successfullyParsed = true;
                this.formEdited = true;
              }
            }
          } catch {
            successfullyParsed = false;
          }
        }
        this.enableOpenVimeo(successfullyParsed);
        if (!successfullyParsed) {
          if (url != '') {
            this.alertError('Problem parsing Vimeo URL!');
          }
          this.activeHotspot.url = '';
          this.formEdited = true;
        }
      }
      this.vimeoUrlChanged = false;
    },
    setVimeoUrlInUI() {
      Vue.nextTick(() => {
        let urlInput = document.querySelector('#vimeo-url');
        if (this.activeHotspot && this.activeHotspot.url != '') {
          let vimeoUrl = 'https://vimeo.com/' + this.activeHotspot.url;
          urlInput.value = vimeoUrl;
          this.enableOpenVimeo(true);
        } else if (urlInput) {
          urlInput.value = "";
          this.enableOpenVimeo(false);
        }
      });
    },
    enableOpenVimeo(enable) {
      let openButton = document.querySelector('#vimeo-open');
      if (enable) {
        openButton.removeAttribute('disabled');
        openButton.classList.remove('disabled');
      } else {
        let att = document.createAttribute('disabled');
        openButton.setAttributeNode(att);
        openButton.classList.add('disabled');
      }
    },
    openVimeoVideo() {
      let url = document.querySelector('#vimeo-url').value;
      var tab = window.open(url, '_blank');
      tab.focus();
    },
    openVideoUrl() {
      let url = document.querySelector('#video-url').value;
      var tab = window.open(url, '_blank');
      tab.focus();
    },
    setupDropzoneHs() {
      let me = this;
      this.dropzonePreviewPathHs = '';
      if (this.activeHotspot.linkType == 'image') {
        if (this.activeHotspot.url != '') {
          this.dropzonePreviewPathHs = '/s3/preview/' + this.activeHotspot.url;
        }
        this.dropzoneTitleHs = this.activeHotspot.url == '' ? 'Drop image to upload.' : 'Drop image to replace.';
        this.dropzoneFiletypes = 'Accepted file types: JPG, PNG, GIF.';
      } else if (this.activeHotspot.linkType == 'pdf') {
        this.dropzoneTitleHs = this.activeHotspot.url == '' ? 'Drop PDF to upload.' : 'Drop PDF to replace.';
        this.dropzoneFiletypes = 'Accepted file types: PDF.';
      }

      if (me.activeHotspot.linkType == 'image') {
        me.dropzoneOptions.acceptedFiles = 'image/png, image/jpeg, image/gif';
      } else if (me.activeHotspot.linkType == 'pdf') {
        me.dropzoneOptions.acceptedFiles = 'application/pdf';
      }
    },
    setupDropzoneBgSound() {
      if (this.backgroundSound) {
        this.dropzoneTitleBgSound = 'Drop sound file to replace.';
      } else {
        this.dropzoneTitleBgSound = 'Drop sound file to upload.';
      }
      this.dropzoneOptions.acceptedFiles = 'audio/mp3, audio/mpeg';
    },
    async rejectPanoramaForm() {
      this.currentNode.userdata.title = this.tmpPanoramaTitle;
      this.currentNode.userdata.description = this.tmpPanoramaDescription;
      if (this.activeHotspot && this.activeHotspot.linkType == 'unknown') {
        await this.deleteHotspot(this.activeHotspot);
      }
      this.formEdited = false;
      this.editPanorama = false;
    },
    async acceptPanoramaForm() {
      if (this.formEdited) {
        let payload = {};
        payload.node = this.currentNode;
        payload.data = {};
        payload.data.userdata = {title: this.currentNode.userdata.title, description: this.currentNode.userdata.description};
        await this.saveNode(payload);
        this.formEdited = false;
      }
      this.editPanorama = false;
    },
    async checkUrl(url) {
      try {
        let urlString = new String(url);
        let uri = '/api/hotspot/testurl';
        let res=await axios.post(uri,{ url: urlString });
        return (res.data);

      } catch (e) {
        console.log(e);
        return {};
      }
    },
  },
  watch: {
    currentProject: function(newVal) {
      this.dropzoneOptions.url = '/api/project/' + newVal._id + '/uploadasset';
      this.dropzoneOptions.headers.Authorization = "Bearer " + this.token;
    },
    currentNode: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.firstOpen = true;
        this.setPlayerNode();
        this.setupDropzoneBgSound();
      }
    },
    activeLinkType: function(newVal) {
      if (this.activeHotspot && this.activeHotspot.__div) {
        this.activeHotspot.__div.src = this.hotspotIconForType(this.activeHotspot.linkType, true);
        this.setupDropzoneHs();
      }
      if (newVal == 'url' || newVal == 'image' || newVal == 'pdf' || newVal == 'video/youtube' || newVal == 'video/vimeo' || newVal == 'video/url') {
        if (!this.activeHotspot.customValues) {
          this.activeHotspot.customValues = {};
        }
        if (newVal == 'url') {
          if (this.activeHotspot.customValues.openintab === undefined) {
            this.activeHotspot.customValues.openintab = false;
            this.formEdited = true;
          }
        } else {
          if (this.activeHotspot.customValues.alttext === undefined) {
            this.activeHotspot.customValues.alttext = '';
            this.formEdited = true;
          }
        }
      }
    },
    activeHotspot: function(newVal, oldVal) {
      if (newVal) {
        this.setupDropzoneHs();
        if (newVal.linkType == 'video/youtube') {
          this.setYoutubeUrlInUI();
        } else if (newVal.linkType == 'video/vimeo') {
          this.setVimeoUrlInUI();
        }
      }
      if (oldVal && oldVal.__div) {
        let prevHs = this.hotspots.find(element => element._id == oldVal._id);
        if (prevHs) {
          oldVal.__div.src = this.hotspotIconForType(prevHs.linkType, false);
        }
      }
      if (newVal && newVal.__div) {
        newVal.__div.src = this.hotspotIconForType(newVal.linkType, true);
      }
      if (oldVal && newVal && newVal._id != oldVal._id && this.formEdited) {
        this.saveHotspotForm(oldVal);
      }
    },
    hotspots: function(newVal, oldVal) {
      if (newVal != oldVal) {
        if (this.firstOpen) {
          this.updatePlayerHotspots();
          let hotspotsTable = document.getElementById('hotspotsTableCollapse');
          if (hotspotsTable && this.hotspotsTableVisible && newVal.length == 0) {
            this.$root.$emit('bv::toggle::collapse', 'hotspotsTableCollapse');
          }
          this.firstOpen = false;
        }
      }
      if (newVal.length == 0) {
        this.$root.$emit('bv::show::popover');
      } else {
        this.$root.$emit('bv::hide::popover');
      }
    },
    editPanorama: function(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal == true) {
          this.tmpPanoramaTitle = this.currentNode.userdata.title;
          this.tmpPanoramaDescription = this.currentNode.userdata.description;
          this.setupDropzoneBgSound();
        }
      }
    }
  }
}
</script>

<style scoped>
.dropzone {
  border: none!important;
  background: var(--light)!important;
  outline: 2px dashed rgb(70, 70, 70);
  color: rgb(70, 70, 70) !important;
  outline-offset: -10px;
  min-height: 100px;
  max-width: 350px;
}
.dropzone:hover {
  background: var(--asu-lighter-color)!important;
  outline: 2px dashed var(--asu-accent-color);
  color: var(--asu-accent-color)!important;
}
.btn-separator:after {
    content: ' ';
    display: block;
    float: left;
    background: #BBBBBB;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin: 0;
    height: 38px;
    width: 3px;
}
</style>
<style>
  .dz-message {
    margin: 5px 0!important;
  }
  .warning-text-wrap {
    white-space: pre-wrap !important;
  }
</style>
