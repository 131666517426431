<template>
  <div class="container-lg px-0">
    <b-card no-body class="rounded-0">
      <b-tabs card pills vertical active-nav-item-class="bg-secondary text-light">
        <b-tab title="Update User" active title-link-class="text-secondary">
          <b-card-text>
            <form v-on:submit.prevent="submitForm">
              <table class="mx-auto mt-3">
                <tr>
                  <td class="text-right pr-2"><label for="userName">User Name:</label></td>
                  <td><input type="text" class="form-control" id="userName" disabled :placeholder="userName"></td>
                </tr>
                <tr>
                  <td class="text-right pr-2"><label for="displayName">Display Name:</label></td>
                  <td><input type="text" class="form-control" id="displayName" placeholder="Display Name" v-model="form.displayName"></td>
                </tr>
        <!--       <tr>
                  <td class="text-right pr-2"><label for="email">Email:</label></td>
                  <td><input type="email" class="form-control" id="email" placeholder="email" v-model="form.email"></td>
                </tr> -->
                <tr>
                  <td></td>
                  <td>
                    <button class="btn btn-outline-primary float-left my-2">Submit</button>
                  </td>
                </tr>
              </table>
            </form>
          </b-card-text>
        </b-tab>
        <b-tab v-if="!isLti" title="Change Password" title-link-class="text-secondary">
          <form v-on:submit.prevent="submitChangePassword">
            <table class="mx-auto mt-3">
              <tr>
                <td class="text-right pr-2"><label for="old_password">Old:</label></td>
                <td><input type="password" class="form-control" id="old_password" placeholder="old password" v-model="changePasswordForm.old"></td>
              </tr>
              <tr>
                <td class="text-right pr-2"><label for="new_password">New:</label></td>
                <td><input type="password" class="form-control" id="new_password" placeholder="new password" v-model="changePasswordForm.new"></td>
              </tr>
              <tr>
                <td class="text-right pr-2"><label for="confirm_password">Confirm:</label></td>
                <td><input type="password" class="form-control" id="confirm_password" placeholder="confirm password" v-model="changePasswordForm.confirm"></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <button class="btn btn-outline-primary float-left my-2">Submit</button>
                </td>
              </tr>
            </table>
          </form>
        </b-tab>
        <b-tab title="Merge Accounts" title-link-class="text-secondary" class="justify-content-center">
          <div class="text-left">
            <p>Merging accounts is used when users have more than one Tour It account (LMS, Infiniscope, or traditional username and password login) and would like to merge their project files into a single account where all of the projects are accessible in one place.</p>
            NOTE:
            <ul>
              <li>This process cannot be undone.</li>
              <li>Merging accounts will give full access to each project and data set from each account.</li>
              <li>Two different Infiniscope accounts cannot be merged into a single Infinicsope account.</li>
            </ul>
            To Merge accounts
            <ol>
              <li>Click on “Get Merge Token”.</li>
              <li>Copy the “Merge Token” either by:<br>
              <span>&emsp;a.&ensp; Selecting the Token and copying it</span><br>
              <span>&emsp;b.&ensp; Clicking on the copy to clipboard button</span>
              </li>
              <li>Open the <em class="font-weight-bold">other</em> Tour It account you would like to merge together.</li>
              <li>Select the User Button in the upper right corner of the screen.</li>
              <li>Go to the Merge Accounts section.</li>
              <li>Paste into “Paste Token to Merge” field.</li>
              <li>Click “Merge Accounts”.</li>
            </ol>
            <hr/>
          </div>
          <div class="pt-2">
            <table class="mx-auto">
              <tr>
                <td/>
                <td>
                  <button class="btn btn-outline-primary float-left" @click="createMergeToken">Get Merge Token</button>
                </td>
              </tr>
              <tr>
                <td class="text-right pr-2"><label>Merge Token:</label></td>
                <td>
                  <input type="text" class="form-control" v-model="mergeToken" disabled>
                </td>
                <td>
                  <button class="btn btn-outline-primary float-left my-2 ml-1" title="Copy to Clipboard" @click="copyTokenToClipboard"><b-icon icon="clipboard-plus"></b-icon></button>
                </td>
              </tr>
              <tr>
                <td class="text-right pr-2"><label>Paste Token to Merge:</label></td>
                <td><input type="text" class="form-control" v-model="mergeAccountForm.token"></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <button class="btn btn-outline-primary float-left my-2" @click="submitMergeAccounts" :disabled="mergeAccountForm.token==''">Merge Accounts</button>
                </td>
              </tr>
            </table>
          </div>
        </b-tab>
        <b-tab title="About" title-link-class="text-secondary">
          <p class="text-left h5">Tour It: Version 3.0<br>
            Developed at: ASUs Center for Education Through eXploration<br>
            Powered by: Garden Gnome Software<br>
            Copyright: 2020-2024
          </p><br>
          <p class="text-left font-italic">
            By using this software you acknowledge that you understand and agree to the following ASU policies and information:
          </p>
          <p class="text-left font-italic">
            Privacy Statement<br>
            <a href="https://www.asu.edu/about/privacy" target="_blank">https://www.asu.edu/about/privacy</a><br><br>
            Terms of Use<br>
            <a href="https://www.asu.edu/about/terms-of-use" target="_blank">https://www.asu.edu/about/terms-of-use</a>
          </p>
          <br>
          <p class="text-left font-italic">
            Support: <a href="mailto: tourit@infiniscope.org">tourit@infiniscope.org</a>
          </p>
        </b-tab>
      </b-tabs>      
    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Login',
  data(){
    return {
      error:'',
      form: {
        displayName: "-",
        email: "-"
      },
      changePasswordForm: {
        old: '',
        new: '',
        confirm: '',
      },
      mergeAccountForm: {
        token: '',
      }
    }
  },
  computed: {
    ...mapState('user', ['userName','displayName','email','mergeToken','isLti'])
  },
  mounted: function()
  {
    this.form.displayName = this.displayName;
    this.form.email = this.email;
  },
  methods: {
    ...mapActions('user', ['login', 'logout','logoutAll','changePassword','changeUser','createMergeToken','mergeAccounts']),
    ...mapActions({ alertInformation: 'alert/information' }),
    submitForm(){
      this.changeUser({ displayName: this.form.displayName, email: this.form.email });
    },
    submitChangePassword() {
      let f=this.changePasswordForm;
      this.changePassword({ oldPw: f.old, newPw: f.new, confirmPw: f.confirm });
    },
    copyTokenToClipboard() {
      navigator.clipboard.writeText(this.mergeToken);
      this.alertInformation('Merge Token copied to Clipboard.');
    },
    submitMergeAccounts() {
      let f=this.mergeAccountForm;
      this.mergeAccounts({ token: f.token });
    },
  }
}

</script>

<style scoped>
h1 {
  margin: 40px 0 0;
}
</style>
